import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { graphql } from "gatsby";
import Link from "../components/LocalizedLink";

import withLayout from "../layout";
import FullLogo from "../components/FullLogo";
import SEO from "../components/seo";
import Chevron from "../components/Chevron";

import "./index.scss";

const Triptic = () => {
    return (
        <div className="triptic">
            <div className="triptic__item">
                <h3 className="triptic__text">
                    <FormattedMessage id="home.triptic-one" />
                </h3>
                <Link className="triptic__cta" to="/about">
                    <FormattedMessage id="home.triptic-one-cta" /> <Chevron />
                </Link>
            </div>
            <div className="triptic__item">
                <h3 className="triptic__text">
                    <FormattedMessage id="home.triptic-two" />
                </h3>
                <Link className="triptic__cta" to="/about#themodel">
                    <FormattedMessage id="home.triptic-two-cta" /> <Chevron />
                </Link>
            </div>
            <div className="triptic__item">
                <h3 className="triptic__text">
                    <FormattedMessage id="home.triptic-three" />
                </h3>
                <Link className="triptic__cta" to="/services">
                    <FormattedMessage id="home.triptic-three-cta" /> <Chevron />
                </Link>
            </div>
        </div>
    );
};

const HomePage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "home.home-title" });

    return (
        <article className="homepage">
            <header className="homepage__header">
                <SEO
                    title={titleText}
                    keywords={[
                        `brief therapy`,
                        `San Francisco`,
                        `Psychotherapy`,
                        `MFT`,
                    ]}
                    lang={props.pageContext.locale}
                />
                <div className="homepage__full-logo">
                    <FullLogo />
                </div>
                <p className="homepage__slogan">
                    <FormattedMessage id="home.home-slogan" />
                </p>
            </header>
            <main className="homepage__main">
                <p>
                    <FormattedMessage
                        id="home.home-one"
                        values={{ strongIn: <strong>, strongOut: </strong> }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="home.home-two"
                        values={{ strongIn: <strong>, strongOut: </strong> }}
                    />
                </p>
                <ul className="homepage__questions">
                    <li>
                        <FormattedMessage id="home.home-q-one" />
                    </li>
                    <li>
                        <FormattedMessage id="home.home-q-two" />
                    </li>
                    <li>
                        <FormattedMessage id="home.home-q-three" />
                    </li>
                    <li>
                        <FormattedMessage id="home.home-q-four" />
                    </li>
                    <li>
                        <FormattedMessage id="home.home-q-five" />
                    </li>
                </ul>
                <Triptic />
                <p>
                    <FormattedMessage id="home.home-here" />
                </p>
                <p>
                    <FormattedMessage id="home.home-covid" />
                </p>
                <p>
                    <FormattedMessage id="home.home-covid-bis" />
                </p>
                <p>
                    <FormattedMessage
                        id="home.home-call"
                        values={{
                            link: (
                                <a href="tel:+14154898816">
                                    <FormattedMessage id="home.home-call-today" />
                                </a>
                            ),
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage id="home.home-wait" />
                </p>
            </main>
        </article>
    );
};

const customProps = {
    localeKey: "home",
};

export default withLayout(customProps)(injectIntl(HomePage));

export const pageQuery = graphql`
    {
        fileName: file(relativePath: { eq: "gloria-lake-chicago-crop.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 800, height: 450, layout: CONSTRAINED)
            }
        }
    }
`;
